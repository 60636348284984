import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { RegisterModel } from '../models/RegisterModel';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { element } from '@angular/core/src/render3/instructions';

@Injectable({
  providedIn: 'root'
})
export class AddUserService {
  //  userInterests: Array<string> = ["Mango", "Orange", "Strawberry", "Apple"];
  constructor(private fb: FormBuilder, private http: HttpClient) {
  }
  objCityList: RegisterModel[];

  userInterests: Array<string> = [
    "Corporate Law",
    "Contract Law",
    "Income Tax, Sales Tax, Customs and Finance Law", 
    "Energy",
    "Constitutional & Administrative",
    "Criminal Law",
    "Environmen",
    "Family Law",
    "Intellectual Property",
    "Land Revenue",
    "Litigation",
    "Public Law",
    "Electoral Law",
    "Banking and Insurance",
    "Labour, Service and Employment",
    "Information, Media and Telecommunication"
  ];
  readonly baseUri = "http://eastlawapi.widerangedigital.com/Api";
  formModel: FormGroup = this.fb.group({
    ID: [''],
    FirstName: ['', Validators.required],
    LastName: ['', Validators.required],
    Emails: this.fb.group({
      EmailID: ['', [Validators.required, Validators.email]],
      ConfirmEmail: ['', Validators.required]
    }, { validator: this.compareEmails }),
    OrgName: ['', Validators.required],
    OrganizationType: ['', Validators.required],
    City: ['', Validators.required],
    MobCode: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]],
    PhoneNo: ['', [Validators.required, Validators.maxLength(7), Validators.minLength(7)]],
    PostalAddress: ['', Validators.required],
    IsTNC: [false],
    Interests: this.addInterestControls(),
    Passwords: this.fb.group({
      Pwd: ['', Validators.required],
      ConfirmPassword: ['', [Validators.required]]
    }, { validator: this.comparePasswords })
  });
  // addInterestControls(){
  //   const arr = this.userInterests.map(element => {
  //     return this.fb.control(false);
  //   });
  //   return this.fb.array(arr);
  // }

  objRegisterModel: RegisterModel = new RegisterModel();
  cityIDs = [];
  comparePasswords(fb: FormGroup) {
    let confirmPswrdCtrl = fb.get('ConfirmPassword');
    if (confirmPswrdCtrl.errors == null || 'passwordMisMatch' in confirmPswrdCtrl.errors) {
      if (fb.get('Pwd').value != confirmPswrdCtrl.value) {
        confirmPswrdCtrl.setErrors({ passwordMisMatch: true });
      }
      else {
        confirmPswrdCtrl.setErrors(null);
      }
    }
  }
  compareEmails(fb: FormGroup) {
    let confirmEmailCtrl = fb.get('ConfirmEmail');
    if (confirmEmailCtrl.errors == null || 'emailMisMatch' in confirmEmailCtrl.errors) {
      if (fb.get('EmailID').value != confirmEmailCtrl.value) {
        confirmEmailCtrl.setErrors({ emailMisMatch: true });
        //console.log("Email Doesnt Match");
      }
      else {
        confirmEmailCtrl.setErrors(null);
        //console.log("Email Match");
      }
    }
  }
  InsertUser() {
    this.objRegisterModel.ID = 0;
    // this.objRegisterModel.EmailID = this.formModel.value.Emails.EmailID;
    this.objRegisterModel.EmailID = this.formModel.value.Emails.EmailID;
    this.objRegisterModel.FullName = (this.formModel.value.FirstName + " " + this.formModel.value.LastName)
    this.objRegisterModel.OrgName = this.formModel.value.OrgName;
    this.objRegisterModel.Pwd = this.formModel.value.Passwords.Pwd;
    this.objRegisterModel.PostalAddress = this.formModel.value.PostalAddress;
    this.objRegisterModel.MobCode = this.formModel.value.MobCode;
    this.objRegisterModel.MobileNo = this.formModel.value.PhoneNo;
    this.objRegisterModel.PhoneNo = (this.formModel.value.MobCode + this.formModel.value.PhoneNo);
    this.objRegisterModel.MobCountryCode = "+92";
    this.objRegisterModel.IsTNC = this.objRegisterModel.IsTNC;
    this.objRegisterModel.PracticeAreaList = this.objRegisterModel.PracticeAreaList;
    this.objRegisterModel.CityID = this.formModel.value.City;
    this.objRegisterModel.OrgTypeID = this.formModel.value.OrganizationType;
    console.log("OrgType ID", this.objRegisterModel);
    
    // console.log("check or not", this.objRegisterModel.IsTNC);
    //console.log("Reached Service", this.objRegisterModel);
    return this.http.post(this.baseUri + "/User/CreateUser", this.objRegisterModel);
    //http://eastlawapi.widerangedigital.com/Api/User/CreateUser
    ///Account/Register"
  }
  IsUserExistByEmail() {
    // return this.http.post(this.baseUri + "/User/IsUserExistByEmail", "asdadasd123123@gmail.com");
    //console.log("Email isAvailable", this.formModel.value.Emails.EmailID);
    return this.http.post(this.baseUri + "/User/IsUserExistByEmail?email=" + this.formModel.value.Emails.EmailID, this.formModel.value.Emails.EmailID);
  }
  addInterestControls() {
    const arr = this.userInterests.map(element => {
      return this.fb.control(false);
    });
    return this.fb.array(arr);
  }
  // GettAllCountries(){
  //   //return this.http.get(this.baseUri + "/common/GetCity?countryCode=PAK").map(res => this.cityIDs = res as RegisterModel[]);
  // }
}
