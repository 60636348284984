import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CitiesModel } from '../models/CitiesModel';
import 'rxjs/add/operator/map';

@Injectable({
  providedIn: 'root'
})
export class CitiesService {
  readonly baseUri = "http://eastlawapi.widerangedigital.com/Api";
  objCities: CitiesModel[];
  constructor(private http: HttpClient) { }

  GetCities(){
    return this.http.get(this.baseUri + "/common/GetCity?countryCode=PAK").map( res => this.objCities = res as CitiesModel[]);
  }

}
