import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { OrgTypeModel } from '../models/OrgTypeModel';

@Injectable({
  providedIn: 'root'
})
export class OrgTypeService {
  readonly baseUri = "http://eastlawapi.widerangedigital.com/Api";
  objOrgType: OrgTypeModel[];
  constructor(private http: HttpClient) { }

  GetAll(){
    return this.http.get(this.baseUri + "/user/GetOrganizationTypes").map( res => this.objOrgType = res as OrgTypeModel[]);
  }

}
