import { Component, OnInit, ɵConsole } from '@angular/core';
import { AddUserService } from 'src/app/shared/add-user.service';
import { RegisterModel } from 'src/app/models/RegisterModel';
import * as $ from 'jquery';
import { of } from 'rxjs';
import { FormControl, FormArray } from '@angular/forms';
import { CitiesModel } from 'src/app/models/CitiesModel';
import { CitiesService } from 'src/app/shared/cities.service';
import { OrgTypeService } from 'src/app/shared/org-type.service';
import { OrgTypeModel } from 'src/app/models/OrgTypeModel';
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  objRegister: RegisterModel = new RegisterModel();
  UserList: RegisterModel[];
  objReturn: RegisterModel = new RegisterModel();
  code;
  selectedInterestValues = [];
  objCities: CitiesModel[];
  objOrgTypes: OrgTypeModel[];
  constructor(private service: AddUserService, private cityService: CitiesService, private orgTypeService: OrgTypeService) {}
  userInterests2: Array<string> = this.service.userInterests;
  get interestsArray() {
    return <FormArray>this.service.formModel.get("Interests");
  }
  // userArray: Array<string> = this.service.userInterests;
  getSelectedValues() {
    this.selectedInterestValues = [];
    this.interestsArray.controls.forEach((control, i) => {
      if (control.value) {
        this.selectedInterestValues.push(this.userInterests2[i]);
      }
    });
    //console.log(this.selectedInterestValues);
  }

  ngOnInit() {
    // console.log(this.interestsArray);
    this.cityService.GetCities().subscribe(res => {
      this.objCities = res as CitiesModel[];
    });
    this.orgTypeService.GetAll().subscribe(res => {
      this.objOrgTypes = res as OrgTypeModel[];
    });
  }
  toggleVisibility(e) {
    this.service.objRegisterModel.IsTNC = e.target.checked;
  }
  onSubmit() {
    this.objRegister.PracticeAreaList = this.selectedInterestValues;
    if (!this.service.formModel.valid) {
      $('.fill-required-error').show();
    }
    else {
      $('.fill-required-error').hide();
      this.objReturn = new RegisterModel();
      $('.fill-required-error').hide();
      this.service.InsertUser().subscribe(
        res => {
          this.objReturn = res as RegisterModel;
          console.log("Inserted", this.objReturn);
          
          if (this.objReturn.ID > 0) {
            $('.success-registered').show();
            this.service.formModel.reset();
          }
          else {
            console.log('MESAFE : ', this.objReturn.ErrorMessage);
            this.code = this.objReturn.ErrorMessage;
          }
        },
        err => {
          console.log(err);
        }
      );
    }
  }


}
