import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LoginVM } from '../models/LoginVM';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private fb: FormBuilder, private http: HttpClient) { }

  readonly baseUri = "http://eastlawapi.widerangedigital.com/api/";
  LoginformModel = this.fb.group({
    EmailID: [null, [Validators.required, Validators.email]],
    Pwd: [null, Validators.required],
  });
  objLoginModel: LoginVM = new LoginVM();
  loginUser() {
    //console.log("User Name Svc : ", this.LoginformModel.value.EmailID, "Password service ", this.LoginformModel.value.Pwd);
    return this.http.post<any>(this.baseUri + "user/LoginUser?email=" + this.LoginformModel.value.EmailID + "&password=" + this.LoginformModel.value.Pwd, this.LoginformModel.value.Pwd).map
      (
        user => {
          if (user && user.token) {
           // console.log("Auth Service : ", user);
            localStorage.setItem('activeUser', JSON.stringify(user));
          }
          return user;
        });
  }

  logout(): void {
    //  localStorage.setItem('isLoggedIn', "false");
    localStorage.removeItem('activeUser');
  }

}
