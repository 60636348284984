export class SearchByRecentSearchModel {
    ResultType: string;
    ID: number;
    Year: string;
    JournalName: string;
    JDate: string;
    Appeallant: string;
    Respondent: string;
    Citation: string;
    CitationRef: string;
    Appeal: string;
    Court: string;
    Keywords: any;
    Statutes: string;
    Headnotes: string;
    TotalSearchCount: number;
    AdvA: string;
    AdvR: string;
    AdvocateA: number;
    AdvocateR: number;
    Appeallant2: string;
    Appeallant3: string;
    AppeallantType: string;
    CasePracticeArea: string;
    CasePracticeArea1: string;
    CaseSummary: ""
    CaseTaggedStatutes: string;
    CaseTaggedStatutesCategory: string;
    CaseTaggedStatutesNew: string;
    CitedBy: number;
    CitedIn: number;
    CleanJudgment: null;
    CourtMasterID: number;
    Court_Area: string;
    CreatedBy: 2919
    CreatedOn: string;
    DateFormated: number;
    FinalReview: number;
    FinalReviewDate: string;
    HeadNotes: string;
    HearDate: string;
    InsideArticleSearch: number;
    InsideCitationsSearch: number;
    InsideRulesSearch: number;
    InsideSectionsSearch: number;
    JournalID: number;
    Judge: number;
    JudgeName: string;
    Judgment: string;
    JudgmentType: string;
    ModifiedBy: null;
    ModifiedOn: string;
    PageNo: string;
    PriorityTagging: number;
    PublicDisplay: null;
    Publish: number;
    Publish_UnPublishDate: null;
    ReadyForPrint_Export: number;
    Respondent2: string;
    Respondent3: string;
    Result: string;
    Status: number;
    isdeleted: number;
    noofjudges: number;
    statutestagging: number;
    uploadfilename: null;
}