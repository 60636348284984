import { Component, OnInit } from '@angular/core';
import { ChageHeaderService } from 'src/app/shared/change-header.service';
import * as $ from 'jquery';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/auth.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  
  constructor(private headerService:ChageHeaderService,private router: Router,public authService: AuthService) 
  {
       
  }

  ngOnInit() {
    var uName=this.headerService.loginPerson;
    $('.loginName').text(uName);
      

  }
logout(): void {
    // console.log('Current Url : ', this.router.url);
    // console.log("Logout"); 
    this.authService.logout();
    this.headerService.HideHeader=true;
    this.headerService.displayHeader=false;

    this.router.navigate(['/member/member-login']);

    // var landingUrl = "#/member/member-login";
    // window.location.href = landingUrl;
   // window.location.reload();

    
    
  }

}
