import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LoginUserService } from 'src/app/shared/login-user.service';
import { LoginVM } from 'src/app/models/LoginVM';
import { RegisterModel } from 'src/app/models/RegisterModel';
import * as $ from 'jquery';
import { ChageHeaderService } from 'src/app/shared/change-header.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/auth.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  objLogin: LoginVM = new LoginVM();
  objUserList: RegisterModel[];
  constructor(private service: LoginUserService, private headerService: ChageHeaderService, private _router: Router, public authService: AuthService) { }
  public show: boolean = false;
  returnUrl: string;
  public displayHeader: boolean;

  @Output() msgEvent = new EventEmitter<boolean>();

  // displayHead(){
  //   return this.displayHeader;
  // }
  ngOnInit() {
    this.returnUrl = '/dashboard';
    this.authService.logout();
  }

  onSubmit() {
    //console.log("Click Event");
    this.service.GetUserLogin().subscribe(
      res => {
        this.objUserList = res as RegisterModel[];
        // console.log("Login Success", res); 
        //console.log("Json LEngth : ", this.objUserList.length > 0);
        if (this.objUserList.length > 0) {
          this.show = false;
          this.clearAll();
          this.headerService.displayHeader = true;
          this.headerService.HideHeader = false;
          this.msgEvent.emit(this.displayHeader);
          this.headerService.loginPerson = this.objUserList[0].FullName;
          // console.log("Full Name : ", this.objUserList[0].FullName);
          localStorage.setItem('isLoggedIn', "true");
          localStorage.setItem('token', this.objUserList[0].ID.toString());
          // var landingUrl = "#/member/member-dashboard";
          this._router.navigate(['member/member-dashboard']);
          // window.location.href = landingUrl;
          $('.cd-user-modal').removeClass("is-visible");
          $('body').removeClass("overflowHide");
        }
        else {
          this.show = true;
          this.clearAll();
          this.headerService.displayHeader = false;
        }
      },
      err => {
        console.log("User not valid : ", err);
      }
    );
  }
  clearAll() {
    $('#signinEmail').val('');
    $('#signinPass').val('');
  }
}
