import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MasterpageComponent } from './masterpage/masterpage.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeComponent } from './views/home/home.component';
import { RouterModule, Routes } from '@angular/router';
import { SlimLoadingBarModule } from 'ng2-slim-loading-bar';
import { LoginComponent } from './views/components/login/login.component';
import { RegisterComponent } from './views/components/register/register.component';
import { AddUserService } from './shared/add-user.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from './views/components/header/header.component';
import { NavLoggedinComponent } from './views/components/nav-loggedin/nav-loggedin.component';
import { AuthGuard } from './auth.guard';
import { ChageHeaderService } from './shared/change-header.service';
import { ToastrModule } from 'ngx-toastr';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'en',
    loadChildren: "src/app/views/site-feedback/site-feedback.module#SiteFeedbackModule"
  },
  {
    path: 'en',
    loadChildren: "src/app/views/privacy-policy/privacy-policy.module#PrivacyPolicyModule"
  },
  {
    path: 'en',
    loadChildren: "src/app/views/contact-us/contact-us.module#ContactUsModule"
  },
  {
    path: 'en',
    loadChildren: "src/app/views/data-coverage/data-coverage.module#DataCoverageModule"
  },
  {
    path: 'en',
    loadChildren: "src/app/views/departments/departments.module#DepartmentsModule"
  },
  {
    path: 'departments',
    loadChildren: "src/app/views/departments-home/departments-home.module#DepartmentsHomeModule"
  },
  {
    path: 'subscription',
    loadChildren: "src/app/views/subscribe/subscribe.module#SubscribeModule"
  },
  {
    path: 'en',
    loadChildren: "src/app/views/terms-conditions/terms-conditions.module#TermsConditionsModule"
  },
  {
    path: 'member',
    loadChildren: "src/app/views/dashboard/dashboard.module#DashboardModule"
  },
  {
    path: 'search/:SearchText',
    loadChildren: "src/app/views/result-listing/result-listing.module#ResultListingModule"
  },
  {
    path: 'cases/:Title',
    loadChildren: "src/app/views/case-detail/case-detail.module#CaseDetailModule"
  },
  {
    path: 'case/:link',
    loadChildren: "src/app/views/judgement-detail/judgement-detail.module#JudgementDetailModule"
  },
  {
    path: 'member',
    loadChildren: "src/app/views/components/login-page/login-page.module#LoginPageModule"
  },
  {
    path: 'member',
    loadChildren: "src/app/views/components/register-page/register-page.module#RegisterPageModule"
  },
  {
    path: 'statutes',
    loadChildren: "src/app/views/legislations/legislations.module#LegislationsModule"
  },
  {
    path: 'statutes',
    loadChildren: "src/app/views/legislations-listing/legislations-listing.module#LegislationsListingModule"
  },
  {
    path: 'departments',
    loadChildren: "src/app/views/department-listing/department-listing.module#DepartmentListingModule"
  }
]
@NgModule({
  declarations: [
    MasterpageComponent,
    HomeComponent,
    LoginComponent,
    RegisterComponent,
    HeaderComponent,
    NavLoggedinComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    SlimLoadingBarModule,
    ToastrModule.forRoot(),
    RouterModule.forRoot(routes,
      { useHash: true })
    // preloadingStrategy: PreloadAllModules,
  ],
  providers: [
    AddUserService,
    ChageHeaderService,
    AuthGuard
  ],
  bootstrap: [MasterpageComponent]
})
export class AppModule { }
