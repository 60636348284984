import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ChageHeaderService {
  displayHeader: boolean = false;
  HideHeader: boolean = true;
  loginPerson:string='';

  constructor() { }
}
