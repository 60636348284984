export class RegisterModel {
    Active: number;
    Address: string;
    Amt: number;
    CityID: number;
    CompanyEmail: string;
    CompanyID: number;
    CompanyLogo: string;
    CompanyName: string;
    CompanyUserAbbr: string;
    CompUser: number;
    ContactPersonEmail: string;
    ContactPersonName: string;
    ContactPersonPhone: string;
    Country: string;
    CreatedBy: number;
    CreatedOn: string;
    EmailID: string;
    FolderID: number;
    FolderName: string;
    FullName: string;
    ID: number;
    InvoiceID: number;
    IsDeleted: number;
    ItemID: number;
    ItemType: string;
    ModifiedBy: number;
    ModifiedOn: string;
    NoOfPCAllowd: number;
    OrgName: string;
    OrgTypeID: number;
    ParentFolder: number;
    PhoneNo: string;
    PlanEnd: string;
    PlanID: number;
    PlanStart: string;
    PostalAddress: string;
    Pwd: string;
    ReceiptNo: string;
    Remarks: string;
    Status: string;
    Uploadfile: string;
    UserID: number;
    UserTypeID: number;
    Verify: number;
    WebURL: string;
    MobCountryCode : string;
    MobCode: string;
    MobileNo : string;
    ErrorMessage : string;
    ResponseMessage: string;
    IsTNC = false;
    PracticeAreaList : string[];
}
