import { Injectable } from '@angular/core';
import { LoginVM } from '../models/LoginVM';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';



@Injectable({
  providedIn: 'root'
})
export class LoginUserService {
  private currentUserSubject: BehaviorSubject<LoginVM>;
  public currentUser: Observable<LoginVM>;
  constructor(private fb: FormBuilder, private http: HttpClient) 
  {
    this.currentUserSubject = new BehaviorSubject<LoginVM>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }
  readonly baseUri ="http://eastlawapi.widerangedigital.com/api/";
  LoginformModel = this.fb.group({
      EmailID: ['', [Validators.required, Validators.email]],
      Pwd: ['', Validators.required],
  });
  objLoginModel: LoginVM = new LoginVM();

  GetUserLogin() {
    this.objLoginModel.userName= this.LoginformModel.value.EmailID;
    this.objLoginModel.password= this.LoginformModel.value.Pwd;
    console.log("User Name Svc : ", this.objLoginModel.userName,"Password service ", this.objLoginModel.password);
    return this.http.post(this.baseUri + "user/LoginUser?email="+this.objLoginModel.userName+"&password="+this.objLoginModel.password,this.objLoginModel.password);  
   }


}

