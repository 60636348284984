import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './shared/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router : Router,private authService: AuthService)
  {

  }

  // canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
  //   const currentUser = this.authService.currentUserValue;
  //   if (currentUser) {
  //       // logged in so return true
  //       return true;
  //   }

    // not logged in so redirect to login page with the return url
    // this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    // return false;
//}
  
  /* Local Storage */

  canActivate(
    route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
    {
      if (localStorage.getItem('activeUser')) {
        // logged in so return true
        // this.router.navigate(['/member/member-dashboard']);
        // console.log("CanActiveRun");
        return true;
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate(['/home']);
    return false;
    // let url: string = state.url;  
    // console.log("Verify Login : ", this.verifyLogin(url));
    // return this.verifyLogin(url);
}
  
  // canActivate(
  //   next: ActivatedRouteSnapshot,
  //   state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
  //   return true;
  // }

  verifyLogin(url) : boolean{
    if(!this.isLoggedIn()){
       this.router.navigate(['/home']);
     
       console.log("CanActiveRun");
        return true;
    }
    else if(this.isLoggedIn()){
      this.router.navigate(['/member/member-dashboard']);
        return false;
    }
}
public isLoggedIn(): boolean{
    let status = false;
    
    if(localStorage.getItem('isLoggedIn') == "true"){
      
      status = true;
    }
    else{
      status = false;
    }
    return status;
}

}
