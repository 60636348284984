import { Component, OnInit } from '@angular/core';
import { Event, NavigationError, NavigationCancel, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { SlimLoadingBarService } from 'ng2-slim-loading-bar';
import { ChageHeaderService } from '../shared/change-header.service';
import { HttpClient } from '@angular/common/http';
import { SearchByRecentService } from '../shared/search-by-recent.service';

@Component({
  selector: 'app-masterpage',
  templateUrl: './masterpage.component.html',
  styleUrls: ['./masterpage.component.css'],
  // animations: [fadeAnimation]
})
export class MasterpageComponent implements OnInit {
  ipAddress:any;
  // getState(outlet) {
  //   return outlet.activatedRouteData.state;
  // }
  // showLoadingIndicator = true;
  // constructor(private _router: Router) {
  //   // Subscribe to the router events observable
  //   this._router.events.subscribe((routerEvent: Event) => {

  //     // On NavigationStart, set showLoadingIndicator to ture
  //     if (routerEvent instanceof NavigationStart) {
  //       this.showLoadingIndicator = true;
  //     }

  //     // On NavigationEnd or NavigationError or NavigationCancel
  //     // set showLoadingIndicator to false
  //     if (routerEvent instanceof NavigationEnd ||
  //       routerEvent instanceof NavigationError ||
  //       routerEvent instanceof NavigationCancel) {
  //       this.showLoadingIndicator = false;
  //     }

  //   });

    
  // }
  showHD: boolean;
  hideAfetrLogin:boolean=true;
  constructor(private _loadingBar: SlimLoadingBarService, private _router: Router,private headerService:ChageHeaderService, private http: HttpClient, private searchByRecentSearches: SearchByRecentService) {
    this._router.events.subscribe((event: Event) => {
      this.navigationInterceptor(event);
      this.showHD=this.headerService.displayHeader;
      this.hideAfetrLogin=this.headerService.HideHeader;
      window.scrollTo(0, 0);
    });
    this.http.get<{ip:string}>('https://jsonip.com')
    .subscribe( data => {
      this.searchByRecentSearches.userIP = data.ip;
      //console.log('th data', this.searchByRecentSearches.userIP);
    });
  }
  private navigationInterceptor(event: Event): void {
    if (event instanceof NavigationStart) {
      this._loadingBar.start();
    }
    if (event instanceof NavigationEnd) {
      this._loadingBar.complete();
    }
    if (event instanceof NavigationCancel) {
      this._loadingBar.stop();
    }
    if (event instanceof NavigationError) {
      this._loadingBar.stop();
    }
  }
  ngOnInit() {
  }
}
