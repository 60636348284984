import { Injectable } from '@angular/core';
import { SearchByRecentSearchModel } from '../models/SearchByRecentSearchModel.model';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SearchByRecentService {
  readonly baseUri = "http://eastlawapi.widerangedigital.com/api";
  objSearchedResults: SearchByRecentSearchModel[];
  objResult: SearchByRecentSearchModel = new SearchByRecentSearchModel();
  ID : any;
  searchWithInText: string;
  constructor(private http: HttpClient) { }
  userIP: any;
  GetAll(searchText: string, pageNumber : number , searchWithInText ?: "") {
    //console.log("User IP:", this.userIP);
    //return this.http.get(this.baseUri + "/search/SearchData?SearchText=" + searchText + "&memberID=3&memberSearchPara=['tax']&clientIP="+ this.userIP +"&browserName=Chrome&sourcePlatform=Windows&requestURL=localhost&startRowIndex="+pageNumber+"&maximumRows=20").map(res => this.objSearchedResults = res as SearchByRecentSearchModel[]);
    //return this.http.get(this.baseUri + "/search/SearchData?SearchText=" + searchText + "&SearchTextWithIn=" + searchWithInText + "&memberID=3&memberSearchPara=['tax']&clientIP="+ this.userIP +"&browserName=Chrome&sourcePlatform=Windows&requestURL=localhost&startRowIndex="+pageNumber+"&maximumRows=20");
    return this.http.get(this.baseUri + "/search/SearchData?SearchText=" + searchText + "&SearchTextWithIn=" + searchWithInText + "&memberID=3&memberSearchPara=[%22tax%22]&clientIP=103.75.246.153&browserName=Chrome&sourcePlatform=Windows&requestURL=localhost&startRowIndex="+pageNumber+"&maximumRows=20");
  }
  GetAllYears(searchText: string){
    return this.http.get(this.baseUri + "/search/GetCasesYearList?SearchText=" + searchText + "&memberID=3&memberSearchPara=[%22tax%22]&clientIP="+ this.userIP +"&browserName=Chrome&sourcePlatform=Windows&requestURL=localhost&startRowIndex=1&maximumRows=20");
  }
  GetAllCourts(searchText: string){
    return this.http.get(this.baseUri + "/search/GetCasesCourtList?SearchText=" + searchText + "&memberID=3&memberSearchPara=[%22tax%22]&clientIP="+ this.userIP +"&browserName=Chrome&sourcePlatform=Windows&requestURL=localhost&startRowIndex=1&maximumRows=20");
  }
  GetSearchResultbyYear(searchText: string, year:string, searchWithInText ?: string){
    return this.http.get(this.baseUri + "/search/SearchDataByYear?SearchText=" + searchText + "&SearchTextWithIn=%27%27&memberID=3&memberSearchPara=[%22tax%22]&clientIP="+ this.userIP +"&browserName=Chrome&sourcePlatform=Windows&requestURL=localhost&startRowIndex=1&maximumRows=20&years="+year+"");
  }
  GetSearchResultbyCourt(searchText: string, court:string, searchWithInText ?: string){
    return this.http.get(this.baseUri + "/search/SearchDataByCourt?SearchText=" + searchText + "&SearchTextWithIn=tax&memberID=3&memberSearchPara=['tax']&clientIP=192.168.1.1&browserName=Chrome&sourcePlatform=Windows&requestURL=localhost&startRowIndex=1&maximumRows=20&courts="+court+"");
  }
}
